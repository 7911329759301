<template>
  <div class="flex flex-col w-full space-y-2">
    <CategoriesItem
      v-for="category in categories"
      :category="category"
      :key="category.id"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
const CategoriesItem = defineAsyncComponent(() => import('@/components/document/CategoriesItem.vue'))
export default {
  name: 'Document',
  components: {
    CategoriesItem
  },
  created () {
    this.initPage()
  },
  computed: {
    ...mapState('pdf', ['categories'])
  },
  methods: {
    ...mapActions('pdf', ['fetchCategories']),
    initPage () {
      this.fetchCategories({})
    }
  }
}
</script>

<style scoped>

</style>
